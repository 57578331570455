import React, { useEffect, useState } from 'react';
import axios from 'axios';
import App from './App';
import { importScript } from './misc/customHooks';

axios.defaults.headers.common['Accept-Language'] = 'de';
axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded';
axios.interceptors.request.use(
  (config) => {
    const bearerToken = sessionStorage.getItem('oauth');

    if (bearerToken !== null) {
      // eslint-disable-next-line
      config.headers.Authorization = `Bearer ${bearerToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default function InitApp() {
  const [isInitializing, setIsInitializing] = useState(true);
  const [hasEdmConfiguration, setHasEdmConfiguration] = useState(false);
  const [edmConfig, setEdmConfig] = useState();

  importScript('/iframeSizer.contentWindow.min.js');

  useEffect(() => {
    setIsInitializing(true);
    setHasEdmConfiguration(false);
    /* if (window.location !== window.parent.location) { */
      window.parent.postMessage('read  loaded', '*');

      const searchParams = new URLSearchParams(window.location.search);
      const configTenant = searchParams.get('edm_config');

      if (configTenant) {
        fetch(`/edm-config-${configTenant}.json`)
          .then((response) => response.json())
          .then((json) => {
            axios.defaults.baseURL = `${json.EDM_BASE_URL}${json.EDM_API_PATH}`;

            setEdmConfig(json);
            setIsInitializing(false);
            setHasEdmConfiguration(true);
          });
      } else {
        window.addEventListener('message', (event) => {
          if (event.data.startsWith('edm|')) {
            const config = JSON.parse(event.data.split('|')[1]);

            axios.defaults.baseURL = `${config.EDM_BASE_URL}${config.EDM_API_PATH}`;
            setEdmConfig(config);
            setIsInitializing(false);
            setHasEdmConfiguration(true);
          }
        });
      }
    /* } else {
      setHasEdmConfiguration(false);
      setEdmConfig({});
      setIsInitializing(false);
    } */
  }, []);

  // TODO: default fallback / error wenn keine config reingegeben wird

  return (
    !isInitializing && (
      <App edmConfig={edmConfig} hasEdmConfiguration={hasEdmConfiguration} />
    )
  );
}
